import { apiCall, apiCallMGMT } from '../util';
import {
  COSTCO_MEMBERSHIP_EDIT_CLICKED,
  COSTCO_MEMBERSHIP_ERROR_MESSAGE_SET,
  COSTCO_MEMBERSHIP_NUMBER_CHANGED,
  COSTCO_MEMBERSHIP_VALIDATED,
  COSTCO_MEMBERSHIP_VALIDATION_FAILED,
  COSTCO_MEMBERSHIP_VALIDATION_STARTED,
  LOADING_QUOTE_DATA_FINISH,
  NOTIFY_ME_EMAIL_VALUES_SAVED,
  PROCEED_WITHOUT_PARTNER_CHANGED,
} from './types';
import {
  logError,
  planChangedByQuoteId,
  rateSummary,
  updateRateQuote,
} from './quoting';
import { AFTER_RATE_TAG } from '../constants';
import { sendAllPetsFSCLead } from './leads';
import { CUSTOM_PLAN_ID } from '../components/quoting/planSection/constants';
import i18n from '../i18n';

export function changeCostcoMembershipNumber(membershipNumber) {
  return {
    membershipNumber,
    type: COSTCO_MEMBERSHIP_NUMBER_CHANGED,
  };
}

const EXEC_MEMBERSHIP = 'EXEC';
const PAYMENT_SUMMARY = 'paymentSummary';
const MESSAGE_FIELD_NAME = 'MessageMembershipValidated';
const EXECUTIVE_MESSAGE_FIELD_NAME = 'MessageMembershipValidatedExecutive';
const EXECUTIVE_BENEFIT_LABEL_FIELD = 'ExecuteMemberBenefitDisplay';
const EXECUTIVE_BENEFIT_VALUE_FIELD = 'ExecuteMemberBenefitValue';
const EXECUTIVE_BENEFIT_FIELDS = [
  EXECUTIVE_BENEFIT_LABEL_FIELD,
  EXECUTIVE_BENEFIT_VALUE_FIELD,
];
const membershipTypeConfig = {
  EXEC: 2,
  REG: 1,
};

export function validateCostcoMembership({
  customerZipCode,
  membershipNumber,
  rateSummaryBody,
  waivableFee,
  useFeeWaived = false,
  useFeeWaivedBackend = false,
}) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: COSTCO_MEMBERSHIP_VALIDATION_STARTED,
      });
      const urlMembership = `api/costco/validatemember/${membershipNumber}`;
      const request = await apiCallMGMT(urlMembership);
      const costcoResponse = await request.json();

      if (costcoResponse.valid) {
        if (!costcoResponse.membershipTier
          || !costcoResponse.membershipTierDescription) {
          dispatch(logError({
            action: 'validateCostcoMembership',
            description: 'Missing membership data',
            endpoint: urlMembership,
            response: costcoResponse,
            statusCode: request.status,
          }));
          dispatch({
            error: i18n.t('enrollment:costco.reenter'),
            type: COSTCO_MEMBERSHIP_VALIDATION_FAILED,
          });
          return;
        }
        const {
          quoting: {
            data: rate,
            partners: { data },
            openQuoteGuid,
          },
        } = getState();
        const { CustomFields } = data;
        const { stateAbrv } = rate || {};
        const isExecutive = costcoResponse.membershipTier === EXEC_MEMBERSHIP;
        let isFeeWaived = false;
        let message = '';
        let fields = {};

        if (useFeeWaivedBackend) {
          const urlConfig =
            `api/Costco/MembershipConfiguration/${stateAbrv}/${isExecutive
              ? membershipTypeConfig.EXEC : membershipTypeConfig.REG}`;
          let configRequest = null;
          let configPayload = null;
          try {
            configRequest = await apiCall(urlConfig);
            configPayload = await configRequest.json();

            if (configPayload.IsValid) {
              isFeeWaived = configPayload.Data.IsEnrollmentFeeWaived;
              message = configPayload.Data.SuccessfulValidationMessage;
              if (isFeeWaived) {
                // Take enrollment fee discount
                const customFields = CustomFields
                  .filter((field) => EXECUTIVE_BENEFIT_FIELDS
                    .includes(field.Name));
                customFields.forEach((field) => {
                  fields[field.Name] = field.Content;
                });
              }
              fields.DetailMessageTitle = configPayload.Data.ExclusiveTitle;
              fields.DetailMessageContent =
                configPayload.Data.Benefits.join(' ');
            } else {
              dispatch(logError({
                action: 'validateCostcoMembership',
                description: configPayload.Message
                  || 'Error getting costco configuration',
                endpoint: urlConfig,
                response: configPayload,
                statusCode: configRequest.status,
              }));
            }
          } catch (exception) {
            dispatch(logError({
              action: 'validateCostcoMembership',
              description: exception.toString(),
              endpoint: urlConfig,
              exception,
              response: configPayload,
              statusCode: configRequest?.status,
            }));
          }
        } else {
          let stateIsNoFeeWaived = false;
          if (!useFeeWaived) {
            // There are states where we are not allowed to give enrollment
            // fee waiver to executive members.
            const STATES_NO_FEE_WAIVED = ['MI', 'RI'];
            stateIsNoFeeWaived = STATES_NO_FEE_WAIVED.includes(stateAbrv);
          }

          let messageName = MESSAGE_FIELD_NAME;
          isFeeWaived =
            waivableFee > 0 && isExecutive && !stateIsNoFeeWaived;
          fields = {};

          if (isFeeWaived) {
            // Take enrollment fee discount and texts
            const customFields = CustomFields
              .filter((field) => field.Section === PAYMENT_SUMMARY);
            customFields.forEach((field) => {
              fields[field.Name] = field.Content;
            });
            messageName = EXECUTIVE_MESSAGE_FIELD_NAME;
          }

          const messageField = CustomFields
            .find((field) => field.Name === messageName);
          message = messageField ? messageField.Content : '';
        }

        // this variable handle 3 different levels
        // GoldStar - REG
        // Business - REG
        // Executive (GoldStar or Business)
        const membershipTypeName = isExecutive
          ? costcoResponse.membershipTierDescription
          : costcoResponse.membershipTypeDescription.replace(/\s/, '');

        // this variable only is the Type straight (Gold Star / Business)
        const membershipType = costcoResponse.membershipTypeDescription;

        dispatch({
          ...fields,
          customerZipCode,
          isFeeWaived,
          membershipStatus: costcoResponse.membershipStatus,
          membershipTier: costcoResponse.membershipTierDescription,
          membershipType,
          membershipTypeName,
          message,
          type: COSTCO_MEMBERSHIP_VALIDATED,
          waivableFee,
        });

        dispatch(rateSummary({
          ...rateSummaryBody,
          IsOpenQuote: !!openQuoteGuid,
          Partner: {
            ...rateSummaryBody.Partner,
            IsFeeWaived: isFeeWaived,
            MembershipType: membershipTypeName,
            MembershipTypeDescription: membershipType,
          },
          QuoteGuid: openQuoteGuid || '',
        }));
      } else {
        dispatch({
          error: costcoResponse.errorMessage,
          type: COSTCO_MEMBERSHIP_VALIDATION_FAILED,
        });
      }
    } catch (_) {
      dispatch({
        error: 'Could not validate member ID, please try again',
        type: COSTCO_MEMBERSHIP_VALIDATION_FAILED,
      });
    }
  };
}

export function costcoMembershipEditClicked() {
  return {
    type: COSTCO_MEMBERSHIP_EDIT_CLICKED,
  };
}

export function costcoMembershipSetErrorMessage(errorMessage) {
  return {
    error: errorMessage,
    type: COSTCO_MEMBERSHIP_ERROR_MESSAGE_SET,
  };
}

export function changeProceedWithoutCostcoMembership({
  diamondClientId,
  effectiveDateCustom,
  eMail,
  employerName,
  nopCommerceClientId,
  parameters,
  petQuoteList,
  quoteId,
}) {
  return async (dispatch, getState) => {
    await dispatch({ type: PROCEED_WITHOUT_PARTNER_CHANGED });
    // Move to custom plan selection all the quotes
    // to avoid mismatching configuration plans
    const { quoting } = getState();
    quoting.quoteSelectedValues.forEach(async (quote) => {
      await dispatch(planChangedByQuoteId({
        planAmount: quote.selectedPlanAmount,
        planId: quote.selectedPlan,
        planPackagedId: CUSTOM_PLAN_ID,
        quoteId: quote.quoteId,
      }));
    });

    await dispatch(updateRateQuote({
      diamondClientId,
      effectiveDateCustom,
      eMail,
      employerName,
      nopCommerceClientId,
      parameters,
      petQuoteList,
      quoteId,
      showLoading: true,
      tag: AFTER_RATE_TAG.removePartner,
    }));
    // Remove promocode from FSC
    dispatch(sendAllPetsFSCLead(true));
  };
}

export function saveNotifyMeValuesEmail({
  email,
  petAge,
  petBreed,
  petBreedId,
  petName,
  petSex,
  zipcode,
}) {
  return {
    type: NOTIFY_ME_EMAIL_VALUES_SAVED,
    valuesEmail: {
      email,
      petAge,
      petBreed,
      petBreedId,
      petName,
      petSex,
      zipcode,
    },
  };
}

export function hideLoading() {
  return { type: LOADING_QUOTE_DATA_FINISH };
}
