import {
  CreditCardType,
  CVV_CARD_LENGTH_MAX,
  CVV_CARD_LENGTH_MIN,
  PetType,
  PRODUCT_TYPE,
  PRODUCT_VALUES,
  OS,
  POWERUP_TYPE,
} from './constants';
import app from './app.json';

const CLIENT_NAME = 'D2C';

// serializeForUri({ param0: 'value 0', param1: 'value:1' }) ===
// 'param0=value%200&param1=value%3A1'
export function serializeForUri(obj = {}) {
  return Object
    .keys(obj)
    .map((key) => `${encodeURI(key)}=${encodeURI(obj[key])}`)
    .join('&');
}

export function genApiUrl(baseURL, path, params) {
  let serializedParams = serializeForUri(params);

  if (serializedParams) {
    serializedParams = `?${serializedParams}`;
  }

  return `${baseURL}/${path}${serializedParams}`;
}

function getApiKey(url) {
  if (url.includes(`${process.env.REACT_APP_API_URL}/customer`)) {
    return {
      'X-Api-Key': process.env.REACT_APP_XAPIKEY,
    };
  }
  return {
    Authorization: 'APIKEY 8f7ecbb6-a62c-44d9-ab88-7b295ba7956c',
  };
}

async function genHttpRequest(url, params = {}) {
  const authHeader = getApiKey(url);
  const options = {
    headers: {
      ...authHeader,
      'Api-version': 'v1',
      'Content-Type': 'application/json',
      'X-Client-Name': CLIENT_NAME,
      'X-Client-Version': app.version,
      ...params.headers,
    },
  };

  if (params.post) {
    options.method = 'POST';
    options.body = JSON.stringify(params.post);
  } else if (params.delete) {
    options.method = 'DELETE';
    options.body = JSON.stringify(params.delete);
  }

  let id = 0;

  if (params.withTimeout) {
    const timeoutTime = params.timeoutTime || 60000;
    if (AbortSignal.timeout) {
      options.signal = AbortSignal.timeout(timeoutTime);
    } else {
      // old browsers
      const controller = new AbortController();
      id = setTimeout(() => controller
        .abort('AbortError: timeout'), timeoutTime);
      options.signal = controller.signal;
    }
  }

  const response = await fetch(url, options);
  clearTimeout(id);

  return response;
}

export async function apiCall(path, params = {}) {
  const url = genApiUrl(process.env.REACT_APP_API_URL, path, params.get);

  return genHttpRequest(url, params);
}

export async function apiCallCostco(path, params = {}) {
  const url = genApiUrl(process.env.REACT_APP_COSTCO_URL, path, params.get);
  const headers = {
    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_KEY_NAME_HERE,
  };

  return genHttpRequest(url, { ...params, headers });
}

export async function apiCallMGMT(path, params = {}) {
  const url = genApiUrl(process.env.REACT_APP_MGMT_API_URL, path, params.get);
  const headers = {
    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_KEY_NAME_HERE,
  };

  return genHttpRequest(url, { ...params, headers });
}

export async function apiCallPurchase(path, params = {}) {
  const url = genApiUrl(
    process.env.REACT_APP_PURCHASE_API_URL,
    path,
    params.get,
  );
  return genHttpRequest(url, { ...params });
}

export async function acquireTokenByPassword({ username, password }) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };

  const bodyObject = {
    client_id: process.env.REACT_APP_B2C_CLIENT_ID,
    grant_type: 'password',
    password,
    response_type: 'token id_token',
    scope: `openid ${process.env.REACT_APP_B2C_CLIENT_ID} offline_access`,
    username,
  };
  const body = serializeForUri(bodyObject);

  const urlB2C = `https://${process.env.REACT_APP_B2C_URL}`
    + `/${process.env.REACT_APP_B2C_TENANT_URL}/`
    + `${process.env.REACT_APP_B2C_SIGNIN_NAME}/oauth2/v2.0/token`;
  try {
    const response = await fetch(urlB2C, {
      body,
      headers,
      method: 'POST',
    });
    const payload = await response.json();
    return payload;
  } catch (error) {
    return null;
  }
}

export function doNothing() {
  // Does exactly what it promises.
  // ... what is "nothing" anyway? https://bit.ly/2Hwo3Vj
}

export function setSessionStorage({ data, key }) {
  if (data && key) {
    sessionStorage.setItem(key, JSON.stringify(data));
  }
}

export function getSessionStorage(key) {
  const data = sessionStorage.getItem(key);

  if (data) {
    return JSON.parse(data);
  }

  return null;
}

export function removeSessionStorage(key) {
  sessionStorage.removeItem(key);
}

function camelize(text) {
  const camelRegex = /[-_\s.]+(.)?/g;
  const temp = text.replace(camelRegex, (_, c) => (
    c ? c.toUpperCase() : ''));
  return temp.substr(0, 1).toLowerCase() + temp.substr(1);
}

export function formatParameters(parametersRaw) {
  const parameters = {};
  Object.keys(parametersRaw).forEach(
    (key) => { parameters[camelize(key)] = parametersRaw[key]; },
  );
  return parameters;
}

export function formatDate(date) {
  if (date.toString().includes('T')) {
    return date.split('T')[0];
  }

  return date;
}

// This function uses setTimeout but can be combined with await in an async
// function to wait for some time before continuing execution.
export function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

/* eslint-disable no-bitwise */
export function generateUUID() {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = ((dt + Math.random()) * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
  });
  return uuid;
}

export function getPetNameFromValue(petValue) {
  switch (petValue) {
    case PetType.Cat.value:
      return PetType.Cat.name;

    case PetType.Dog.value:
      return PetType.Dog.name;

    default:
      return 'undefined';
  }
}

export function validateEmail(email) {
  const regexString = '^[a-zA-Z0-9_-]+(\\.[a-zA-Z0-9_-]+)*@'
    + '[a-zA-Z0-9_-]+(\\.[a-zA-Z0-9_-]+)*\\.[a-zA-Z0-9]{1,}$';
  const regex = new RegExp(regexString);

  return regex.test(String(email).toLowerCase());
}

export function testOnlyNumber(value) {
  const REGEX_NUMBER = /^[0-9]*$/;

  return REGEX_NUMBER.test(value) || !value;
}

export function testContainLetter(value) {
  const REGEX_LETTER = /[a-zA-Z]/;

  return REGEX_LETTER.test(value);
}

export function testContainNumber(value) {
  const REGEX_ANY_NUMBER = /[0-9]/;

  return REGEX_ANY_NUMBER.test(value);
}

export function currencyFormat({ fixed = 2, value = 0 }) {
  if (value === 0) {
    return `${value.toFixed(fixed)}`;
  }

  const formattedValue = `${Math.abs(value)
    .toFixed(fixed).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;

  return formattedValue;
}

export const isPaycheck = app.paycheck;
export const {
  useEnrollmentflag,
  useGoogleTagManager,
  useOneInc,
  useSecondaryPetParent,
  useTrackingEvents,
} = app;

// For more information about the Routing validation see:
// http://en.wikipedia.org/wiki/Routing_transit_number
export function isRoutingNumberValid(routing) {
  if (routing.length !== 9) {
    return false;
  }

  const checksumTotal =
    (
      7 * (parseInt(routing.charAt(0), 10)
        + parseInt(routing.charAt(3), 10)
        + parseInt(routing.charAt(6), 10))
    )
    + (
      3 * (parseInt(routing.charAt(1), 10)
        + parseInt(routing.charAt(4), 10)
        + parseInt(routing.charAt(7), 10))
    )
    + (
      9 * (parseInt(routing.charAt(2), 10)
        + parseInt(routing.charAt(5), 10)
        + parseInt(routing.charAt(8), 10))
    );

  const checksumMod = checksumTotal % 10;
  if (checksumTotal === 0 || checksumMod !== 0) {
    return false;
  }

  return true;
}

export function getProductType(productId) {
  const { FPI_VALUES, IHC_VALUES } = PRODUCT_VALUES;

  if (IHC_VALUES.indexOf(productId) >= 0) {
    return PRODUCT_TYPE.IHC;
  }

  if (FPI_VALUES.indexOf(productId) >= 0) {
    return PRODUCT_TYPE.FPI;
  }

  return PRODUCT_TYPE.Unknown;
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
let timeout;
export function debounce(func, wait, immediate) {
  clearTimeout(timeout);

  return function f(...args) {
    const context = this;

    function later() {
      timeout = null;

      if (!immediate) {
        func.apply(context, args);
      }
    }

    const callNow = immediate && !timeout;
    timeout = setTimeout(later, wait);

    if (callNow) {
      func.apply(context, args);
    }
  };
}

export function phoneMask(value = '') {
  const sanitizeText = value ? value.replace(/-/g, '') : '';
  const formattedValue = sanitizeText.split(/(\d{0,3})(\d{0,3})(\d{0,4})/)
    .filter((item) => item.length > 0);

  return formattedValue.join('-');
}

export function decodeImageBase64(imageEncoded) {
  return `data:image/png;base64,${imageEncoded}`;
}

export function isValidDiamondStreet(value) {
  const newValue = String(value).replace('P.O.', '').replace('P. O.', '');

  // eslint-disable-next-line max-len
  const REGEX_STREET_ADDRESS = /^\s*((([a-zA-Z]+\s){1}((?=.*[0-9]+))|([0-9]+\s){1}((?=.*[a-zA-Z]+)))|((?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)+\s){1}((?=.*[0-9a-zA-Z]+)))+.*\s*$/;
  const isStreetAddressValid = REGEX_STREET_ADDRESS.test(newValue.trim());

  return isStreetAddressValid;
}

/**
 * store.data.insuranceProduct.Id
 * @param {*} productId int
 */
export function isProductFPI(productId) {
  const { FPI_VALUES } = PRODUCT_VALUES;

  return FPI_VALUES.indexOf(productId) >= 0;
}

export function testNumberWithDecimal(value) {
  const REGEX_NUMBER = /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/;

  return REGEX_NUMBER.test(value) || !value;
}

/**
 * @param {string} stringToClean
 * @returns {string} The same stringToClean without emojis
 */
export function removeEmojis(stringToClean) {
  // eslint-disable-next-line max-len
  const REGEX_EMOJIS = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
  return stringToClean.replace(REGEX_EMOJIS, '');
}

export function scrollToElement(idElement) {
  try {
    const element = document.getElementById(idElement);
    window.scrollTo(element.offsetLeft, element.offsetTop);
  } catch (_) {
    doNothing();
  }
}

export function getElementOffsetTop(elementName = '', modifier = 0) {
  const element = document.getElementById(elementName);

  if (element) {
    return element.offsetTop - modifier;
  }

  return 0;
}

export function isValidCardCVV(cvv) {
  if (cvv.length > CVV_CARD_LENGTH_MAX
    || cvv.length < CVV_CARD_LENGTH_MIN
    || !testOnlyNumber(cvv)) {
    return false;
  }
  return true;
}

export function getCreditCardType(cardNumber) {
  const REGEX_AMEX = /^3[47]/;
  const REGEX_DINER_CLUB = /^3[068]/;
  const REGEX_VISA = /^4[0-9]/;

  if (REGEX_AMEX.test(cardNumber)) {
    return { length: 15, type: CreditCardType.AmericanExpress.name };
  }

  if (REGEX_VISA.test(cardNumber)) {
    return { length: 16, type: CreditCardType.Visa.name };
  }

  if (REGEX_DINER_CLUB.test(cardNumber)) {
    return { length: 14, type: CreditCardType.DinnersClub.name };
  }

  return {
    length: 16,
    type: CreditCardType.Other.name,
  };
}

export function isValidCardNumber(cardNumber) {
  const cardType = getCreditCardType(cardNumber);

  if (cardType.type === CreditCardType.DinnersClub.name) {
    return false;
  }

  const CARD_NUMBER_LENGTH = cardType.length;

  if (cardNumber.length !== CARD_NUMBER_LENGTH
    || !testOnlyNumber(cardNumber)) {
    return false;
  }
  return true;
}

export function isValidCardExpirationDate(expirationDate) {
  const EXPIRATION_DATE_LENGTH = 4;
  let isValidDate = false;
  if (expirationDate.length === 4) {
    const today = new Date();
    const currentYearRaw = today.getFullYear().toString().substring(2, 4);
    const currentYear = parseInt(currentYearRaw, 10);
    const currentMonth = parseInt(today.getMonth(), 10) + 1;// starts from 0
    const monthRaw = expirationDate.substring(0, 2);
    const month = parseInt(monthRaw, 10);
    const yearRaw = expirationDate.substring(2, 4);
    const year = parseInt(yearRaw, 10);

    if (year === currentYear) {
      isValidDate = month > currentMonth && month <= 12;
    } else if (year > currentYearRaw) {
      isValidDate = month >= 1 && month <= 12;
    } else {
      isValidDate = false;
    }
  }

  if (expirationDate.length !== EXPIRATION_DATE_LENGTH
    || !testOnlyNumber(expirationDate) || !isValidDate) {
    return false;
  }
  return true;
}

export function formatDataDMY(utcFormatDate) {
  const splitedParts = utcFormatDate.split('-');
  return `${splitedParts[2]}-${splitedParts[1]}-${splitedParts[0]}`;
}

export function createEffectiveDate() {
  const date = new Date();
  date.setDate(date.getDate() + 1);

  return date.toISOString().split('T')[0];
}

export function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return OS.android;
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return OS.ios;
  }

  return OS.unknown;
}

export function closeWindow() {
  const os = getMobileOperatingSystem();

  if (os === OS.ios) {
    window.webkit.messageHandlers.dismissWebView.postMessage('finish_task');
  } else if (os === OS.android) {
    window.ReactNativeWebView.postMessage('close-page');
  } else {
    window.close();
  }
}

export function validatePartnerIds({ parameters, synonyms }) {
  const response = {};
  const customParameter = Object.keys(parameters)
    .find((parameter) => synonyms
      .map((synonym) => synonym.toLowerCase())
      .includes(parameter.toLowerCase()));

  if (customParameter) {
    response.Name = customParameter;
    response.Value = parameters[customParameter];
  }

  return response;
}

export function loadEmbeddedScript({
  onLoadCallback,
  scriptAsync,
  scriptDefer,
  scriptId,
  scriptInnerHTML,
  scriptSrc,
  scriptType = 'text/javascript',
}) {
  if (document.getElementById(scriptId)) {
    return;
  }
  let script = document.createElement('script');
  if (scriptType === 'text/css') {
    script = document.createElement('style');
  }
  script.id = scriptId;
  script.type = scriptType;
  if (scriptSrc) {
    script.src = scriptSrc;
  }

  script.async = scriptAsync;
  script.defer = scriptDefer;

  if (scriptInnerHTML) {
    script.innerHTML = scriptInnerHTML;
  }
  if (onLoadCallback) {
    script.onload = () => onLoadCallback();
  }
  document.body.appendChild(script);
}

export function extoleQuoteEvent({
  email,
  petAge,
  petBreed,
  petName,
  petSex,
  petType,
  promoCode,
  quoteId,
  state,
  zipcode,
}) {
  window.extole?.createZone({
    data: {
      email,
      pet_age: petAge,
      pet_breed: petBreed,
      pet_name: petName,
      pet_sex: petSex,
      pet_type: petType,
      promo_code: promoCode,
      quote_id: quoteId,
      state,
      zipcode,
    },
    name: 'quote_submitted',
  });
}

export function extolePurchaseEvent({
  cartValue,
  email,
  firstName,
  lastName,
  membershipType,
  partnerUserId,
  policyId,
  state,
}) {
  window.extole?.createZone({
    data: {
      cart_value: cartValue,
      email,
      first_name: firstName,
      last_name: lastName,
      membership_type: membershipType,
      partner_user_id: partnerUserId,
      policy_id: policyId,
      state,
    },
    name: 'policy_purchased',
  });
}

export const isProdEnv = process.env.REACT_APP_NAME === 'Prod';

export function getUtmParams(parameters) {
  return Object.fromEntries(Object.entries(parameters)
    .filter(([key]) => key.startsWith('utm_')));
}

export function getAgeInYears(ageName = '') {
  if (ageName?.toLowerCase().includes('year')) {
    return ageName?.split(' ')[0];
  }

  return '0';
}

// Use this only if you can't use useRef,
// React not recommend manipulate DOM directly
export function forceFocusElement(id) {
  const element = document.getElementById(id);

  if (element) {
    element.focus();
  }
}

export function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * @param {string} value String to force to boolean
 * @returns {boolean} the parsed value
 */
export function toBoolean(value = '') {
  return !!value.match(/^(true|1|yes)$/i);
}

export function getB2CRedirectUrl() {
  if (window.location.hostname === 'localhost') {
    return 'http://localhost:3000';
  }

  switch (process.env.REACT_APP_NAME) {
    case 'test-2':
      return 'https://test-2-d2c-quote.figopetinsurance.com';
    case 'test-3':
      return 'https://test-3-d2c-quote.figopetinsurance.com';
    case 'test-4':
      return 'https://c61-test-4-d2c-quote.azurewebsites.net';
    case 'Prod':
      return 'https://quote.figopetinsurance.com';
    case 'CPSC':
      return 'https://c61-cpsc-d2c-quote.azurewebsites.net';
    default:
      return 'https://test-3-d2c-quote.figopetinsurance.com';
  }
}

export const appVersion = app.version;

export const useElectronicConsent = app['fpi-10540'];

export const removeDuplicates = (array, key) => {
  const uniqueSet = new Set();

  return array.filter((item) => {
    const value = key ? item[key] : item;
    if (!uniqueSet.has(value)) {
      uniqueSet.add(value);
      return true;
    }
    return false;
  });
};

/**
 * Validates if all the powerups have been reviewed by the user.
 * If not, returns an array with the powerup ids not reviewed
 * @param {object} petQuoteRateItem
 * @returns {{isValid: boolean, modifierIdNotSelectedList: int[]}}
 */
export const validatePowerupsSelected = (petQuoteRateItem) => {
  if (!petQuoteRateItem) {
    return { isValid: false, modifierIdNotSelectedList: [] };
  }
  const possiblePowerups = petQuoteRateItem.InsuranceModifiers.filter(
    (modifier) => modifier.InsuranceModifierTypeId === POWERUP_TYPE
      && modifier.IsVisible,
  );

  const modifierIdNotSelected = [];

  possiblePowerups.forEach((powerup) => {
    if (powerup.IsSelected === null || powerup.IsSelected === undefined) {
      modifierIdNotSelected.push(powerup.Id);
    }
  });

  return {
    isValid: !modifierIdNotSelected.length,
    modifierIdNotSelectedList: modifierIdNotSelected,
  };
};

export const {
  useCostcoTN,
  useCustomEffectiveDate,
  useCustomPlanPills,
  useFSCLeads,
  useGoodDogTN,
  useMarketingChannel,
  useRetryPurchase,
  usePowerupsRadioButtons,
  useROPC,
} = app;
