import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { saveQuoteMobileVisibleChanged, setPowerUpErrors } from '../../actions';

import ill16 from '../../assets/ills/ill-16.svg';

import './SaveYourQuoteButton.css';
import { useEnrollmentflag, validatePowerupsSelected } from '../../util';

const SaveYourQuoteButton = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('quoting');
  const {
    data,
    petQuoteSelected,
  } = useSelector(({ quoting }) => quoting, shallowEqual);

  const handleSaveYourQuote = useCallback(() => {
    const petQuoteRateItem = data.petQuoteResponseList
      .find((pqRateItem) => pqRateItem.petQuoteId === petQuoteSelected);
    const powerUpValidation = validatePowerupsSelected(petQuoteRateItem);
    if (!powerUpValidation.isValid) {
      dispatch(setPowerUpErrors(powerUpValidation.modifierIdNotSelectedList));
      return;
    }
    dispatch(saveQuoteMobileVisibleChanged(true));
  }, [data, dispatch, petQuoteSelected]);

  return (
    <section
      aria-label={t('saveQuoteDetails')}
      className="Save-your-quote-button-container"
    >
      <span>{t('saveQuote.notReady')}</span>

      <button
        aria-controls="saveYourQuotemodalContainer"
        className="Card-plan-footer-email-your-quote-button"
        id="saveYourQuoteTabletButton"
        onClick={handleSaveYourQuote}
        type="button"
      >
        {t('saveQuote.sendQuote')}
      </button>

      {!useEnrollmentflag && (
        <img
          alt=""
          aria-hidden
          className="Quote-content-image-2 No-tablet"
          src={ill16}
        />
      )}
    </section>
  );
};

export { SaveYourQuoteButton };
