import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import plusIcon from '../../assets/plus-icon.svg';
import lessIcon from '../../assets/less-icon.svg';

import './WhatFigoCovers.css';

const CoveredElements = ({ ariaLabel }) => {
  const [activeIndexes, setActiveIndexes] = useState([]);

  const store = useSelector((coverage) => coverage.quoting);
  const whatsCovered = store.data?.CoverageInformation?.WhatsCovered || [];

  const toggleAccordion = (index) => {
    setActiveIndexes((prevActiveIndexes) => {
      if (prevActiveIndexes.includes(index)) {
        return prevActiveIndexes.filter((i) => i !== index);
      }
      return [...prevActiveIndexes, index];
    });
  };

  return (
    <div aria-label={ariaLabel} className="what-figo-covers-box">
      <ul>
        {whatsCovered.map((coverage) => (
          <li key={String(coverage.Id)} className="what-figo-covers-item">
            <button
              aria-controls={`whatsCovered${coverage.Id}`}
              aria-expanded={activeIndexes.includes(coverage.Id)}
              className={`${activeIndexes.includes(coverage.Id)
                ? 'Like-span what-figo-covers-item-order-opened'
                : 'Like-span what-figo-covers-item-order'}`}
              onClick={() => toggleAccordion(coverage.Id)}
              type="button"
            >
              <span
                aria-label={coverage.Title}
                className="what-figo-covers-boxitem"
              >
                <img
                  alt=""
                  className="what-figo-covers-icon"
                  src={coverage.Icon}
                />

                <p className="what-figo-covers-item-title">{coverage.Title}</p>
              </span>

              <img
                alt=""
                className="what-figo-covers-plus-less-icon"
                src={activeIndexes.includes(coverage.Id) ? lessIcon : plusIcon}
              />
            </button>

            <div
              className={`what-figo-covers-accordion-content ${
                activeIndexes.includes(coverage.Id)
                  ? '' : 'what-figo-covers-accordion-content-hidden'}`}
              id={`whatsCovered${coverage.Id}`}
            >
              <p className="what-figo-covers-text">{coverage.Text}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CoveredElements;
