import React, { useMemo, useRef, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ModalContainer from './ModalContainer';
import ScrollableDiv from './ScrollableDiv';
import MultiModalInfoItemMenu from './MultiModalInfoItemMenu';
import MultiModalInfoItemContent
from './MultiModalInfoItemContent';
import figoLogo from '../../assets/figo-logo-shield.svg';
import {
  multiModalCustomContenCleared,
  needToKnowModalVisibleChanged,
  petCoPayModalVisibleChanged,
  termsAndCondModalVisibleChanged,
  wellnessVisibleChanged,
} from '../../actions/quoting';
import { MODAL_RELATION_LIST, RED_BACKGROUND } from '../../constants';
import { useResponsive, useOnEscEvent } from '../../hooks';
import {
  delay,
  useElectronicConsent,
  useEnrollmentflag,
} from '../../util';
import Shimmer from './Shimmer';
import './MultiModalInfo.css';

const ELECTRONIC_CONSENT_MODAL_ID = 10;

const MultiModalInfo = ({
  menuSelected = 0,
  modalId = 0,
  show = false,
  keepInDOM = false,
  id = '',
}) => {
  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const sideMenuRef = useRef();
  const dispatch = useDispatch();
  const {
    multiModalCustomData,
    multiModalData,
    multiModalUserSelection: { needToKnowIdSelected },
    rateSummary: { PetQuotes },
  } = store;
  const {
    NEED_TO_KNOW_MODAL_ID,
    PET_CO_PAY_MODAL_ID,
    TERMS_AND_COND_MODAL_ID,
    WELLNESS_MODAL_ID,
  } = MODAL_RELATION_LIST;
  const { isTablet } = useResponsive();
  const [isVisible, setIsVisible] = useState(false);
  const WHATS_NOT_COVERED_ID = 2;

  function isElectronicConsent() {
    if (PetQuotes && PetQuotes[0]) {
      return PetQuotes[0].IsElectronicConsent;
    }

    return false;
  }

  const handleDelayShimmer = async () => {
    await delay(300);
    setIsVisible(false);
  };

  function handleMenuItemClick(categoryId) {
    const visible = true;

    switch (modalId) {
      case NEED_TO_KNOW_MODAL_ID:
        dispatch(needToKnowModalVisibleChanged({ categoryId, visible }));
        break;

      case PET_CO_PAY_MODAL_ID:
        dispatch(petCoPayModalVisibleChanged({ categoryId, visible }));
        break;

      case TERMS_AND_COND_MODAL_ID:
        dispatch(termsAndCondModalVisibleChanged({ categoryId, visible }));
        break;

      case WELLNESS_MODAL_ID:
        dispatch(wellnessVisibleChanged({ categoryId, visible }));
        break;

      default:
        break;
    }

    setIsVisible(true);
    handleDelayShimmer();
  }

  function getModalTitle() {
    if (multiModalData.InsuranceMultiModalSectionDtoList.length) {
      const data = multiModalData.InsuranceMultiModalSectionDtoList
        .find((element) => element.Id === modalId);

      return data ? data.SectionName : '';
    }

    return '';
  }

  function getCategoryList() {
    if (multiModalCustomData) {
      return multiModalCustomData.category;
    }

    if (multiModalData.InsuranceMultiModalSectionDtoList.length) {
      const categoryList = multiModalData
        .InsuranceMultiModalSectionDtoList
        .find((element) => element.Id === modalId) || {};

      if (useElectronicConsent) {
        if (modalId === TERMS_AND_COND_MODAL_ID
          && !isElectronicConsent()) {
          return categoryList.InsuranceMultiModalCategoryDtoList
            .filter((element) => element.Id !== ELECTRONIC_CONSENT_MODAL_ID);
        }
      }

      return categoryList.InsuranceMultiModalCategoryDtoList;
    }

    return [];
  }

  const categoryList = getCategoryList() || [];

  function getHeaderTitle() {
    if (multiModalCustomData) {
      return multiModalCustomData.title;
    }

    const categoryContent = categoryList
      .find((element) => element.Id === menuSelected) || {};
    return categoryContent.CategoryHeader;
  }

  function getCategoryContent() {
    if (multiModalCustomData) {
      return multiModalCustomData.content;
    }

    const menuContentList = multiModalData.InsuranceMultiModalSectionDtoList;

    if (menuContentList.length) {
      let menuContent;
      const menuContentCagegoryList = menuContentList
        .find((element) => element.Id === modalId);

      if (menuContentCagegoryList) {
        menuContent = menuContentCagegoryList.InsuranceMultiModalCategoryDtoList
          .find((element) => element.Id === menuSelected);
      }

      if (menuContent) {
        return menuContent.InsuranceMultiModalItemsDtoList;
      }
    }

    return [];
  }

  const subtitle = useMemo(() => {
    const categoryContent = categoryList
      .find((element) => element.Id === menuSelected) || {};
    return categoryContent.SubTitle;
  }, [categoryList, menuSelected]);

  const headerTitle = getHeaderTitle();
  const categoryContent = getCategoryContent();

  function onCloseModal() {
    if (multiModalCustomData) {
      dispatch(multiModalCustomContenCleared());
      return;
    }

    const visible = false;

    switch (modalId) {
      case NEED_TO_KNOW_MODAL_ID: {
        dispatch(needToKnowModalVisibleChanged({
          categoryId: needToKnowIdSelected,
          visible,
        }));
        break;
      }

      case PET_CO_PAY_MODAL_ID:
        dispatch(petCoPayModalVisibleChanged({ visible }));
        break;

      case TERMS_AND_COND_MODAL_ID:
        dispatch(termsAndCondModalVisibleChanged({ visible }));
        break;

      case WELLNESS_MODAL_ID:
        dispatch(wellnessVisibleChanged({ visible }));
        break;

      default:
        break;
    }
  }

  useOnEscEvent(() => {
    onCloseModal();
  }, []);

  function renderSideMenu() {
    return categoryList.map((item) => {
      if (WELLNESS_MODAL_ID !== modalId) {
        return (
          <MultiModalInfoItemMenu
            key={String(item.Id)}
            id={item.Id}
            onClick={handleMenuItemClick}
            selected={item.Id === menuSelected}
            title={item.CategoryHeader}
          />
        );
      }

      return (
        <MultiModalInfoItemMenu
          key={String(item.Id)}
          id={item.Id}
          onClick={handleMenuItemClick}
          selected={item.Id === menuSelected}
          title={item.CategoryHeader}
        />
      );
    });
  }

  function renderModalContent() {
    return categoryContent.map((element) => (
      <MultiModalInfoItemContent
        key={String(element.Id)}
        bulletIcon={menuSelected === RED_BACKGROUND}
        customStyle={useEnrollmentflag && modalId === NEED_TO_KNOW_MODAL_ID && {
          subtitleStyle: 'Multi-modal-info-content-row-subtitle-enrollment',
          titleStyle: 'Multi-modal-info-content-row-title-enrollment',
        }}
        icon={element.BulletIconUrl || element.BulletIcon}
        isBold={element.IsBold}
        isNotCovered={modalId === NEED_TO_KNOW_MODAL_ID
          && menuSelected === WHATS_NOT_COVERED_ID}
        singleItem={categoryContent.length === 1}
        subtitle={element.ItemSubText === undefined
          ? element.ItemSubtext : element.ItemSubText}
        title={element.ItemLabel}
        value={element.Value}
      />
    ));
  }

  return (
    <ModalContainer
      ariaTitle={headerTitle}
      cancelable
      classCloseIcon="Modal-card-button-close-icon"
      disableClickOutside={isTablet}
      handleOutClick={onCloseModal}
      id={id}
      idPrefix={id}
      isMultiModal
      keepInDOM={keepInDOM}
      show={show}
    >
      <div className="Multi-modal-info-container">
        <div
          ref={sideMenuRef}
          aria-live="off"
          className="Multi-modal-info-col-left"
        >
          <img
            alt=""
            className="Multi-modal-info-figo-logo"
            src={figoLogo}
          />

          <span className="Multi-modal-info-title">{getModalTitle()}</span>

          <ScrollableDiv
            className="Multi-modal-info-menu-container"
          >
            {renderSideMenu()}
          </ScrollableDiv>
        </div>

        <ScrollableDiv
          className="Multi-modal-info-col-right"
          scrollbarMargin
        >
          <Shimmer visible={isVisible}>
            <div
              aria-live="polite"
              className="Multi-modal-info-content-container"
            >

              <span className={'Inter-semi-bold-18'
                + ' Multi-modal-info-content-title'}
              >
                {headerTitle}
              </span>

              {subtitle && (
                <span className="Multi-modal-info-content-subtitle">
                  {subtitle}
                </span>
              )}

              <ul className="Multi-modal-info-content-rows-container">
                {renderModalContent()}
              </ul>
            </div>
          </Shimmer>
        </ScrollableDiv>
      </div>
    </ModalContainer>
  );
};

export default MultiModalInfo;
